import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../components/Layout/Layout'

// markup
const IndexPage = () => {
  useLayoutEffect(() => {
    var s = document.createElement('script')
    s.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js'
    )
    s.onload = () => console.log('redoc script added')
    document.body.appendChild(s)
  }, [])

  return (
    <Layout constricted>
      <title>Consupedia Api Documentation</title>
      <div className="hero dark-bg alignfull food-pile">
        <div className="content-container">
          <div>
            <h1>
              Build with <span>Consupedia</span>
            </h1>
            <br />

            <br />
            <br />
          </div>
        </div>
      </div>
      <section className="alignfull">
        <div className="section-container">
          <div>
            <p style={{ maxWidth: '1000px' }}>
              <strong>
                Get creative with the Consupedia developer tools and services.
              </strong>{' '}
              We aim to provide the largest and most comprehensive developer
              platform for sustainable product data. We are just getting
              started.
            </p>
            <br />
            <br />
            <div className="cols" style={{ marginBottom: '3rem' }}>
              <div className="col">
                <h2>Data enrichment</h2>{' '}
                <p>
                  We provide a suite of tools and services to enrich your
                  product data with the best in class sustainability
                  information.
                </p>
                <a href="/documentation">Read about our services</a>
                <br />
                <a
                  href="mailto:tech-support@consupedia.com"
                  style={{ marginTop: '0.5rem', display: 'block' }}
                >
                  Contact us for custom integrations
                </a>
              </div>
              <div className="col">
                <h2>REST Api</h2>
                <p>
                  Use the Consupedia REST API to get access to sustainability
                  data and create your own services that help drive change
                  towards a sustainable society.
                </p>
                <a href="/api-reference">Go to API Documentation</a>
              </div>
              <div className="col">
                <h2>Widgets</h2>
                <p>
                  Add our widgets to your website to get a quick overview of a
                  products sustainability.
                </p>
                <a href="https://widget.consupedia.se">Go to widgets</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>You need any help with the Consupedia platform? Get in touch:</p>
            <p>
              <strong>
                <a href="mailto:tech-support@consupedia.com">
                  tech-support@consupedia.com
                </a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
